.user_mode {
  .hero-container {
    position: relative;
    padding: 0 0 40px 0;
    overflow: hidden;
    // blue circle
    &:before {
      content: '';
      position: absolute;
      width: 70%;
      background-color: rgba(1, 33, 99, 0.5);
      padding-top: 70%;
      display: block;
      border-radius: 50%;
      left: -22%;
      top: -45%;
      z-index: 20;
    }
    .column {
      padding: 0;
      .pageElement {
        padding: 0;
        margin: 0;
      }
      // Hero Photo
      .pageEl:nth-child(1) {
        position: relative;
      }
      // Title - 2 Lines
      .pageEl:nth-child(2) {
        position: absolute;
        top: 35px;
        left: 20px;
        width: 50%;
        z-index: 30;
        .text {
          h1 {
            color: #fff;
            line-height: 1;
            text-transform: uppercase;
            position: relative;
            &:first-of-type {
              font-size: 16px;
              font-weight: 400;
            }
            &:last-of-type {
              font-size: 28px;
              strong {
                color: #fff;
                font-weight: 900;
              }
              &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 42%;
                border-bottom: 2px solid $secondary-color;
                display: block;
              }
            }
          }
          p {
            display: none;
          }
        }
      }
      // Red Buttons
      .pageEl {
        // Button left
        &:nth-child(n + 3) {
          z-index: 30;
          position: absolute;
          bottom: -40px;
          left: 0;
					width: 50%;

          border-right: 1px solid white;
          a {
            justify-content: center;
						text-transform: none;
						border-radius: 0;
						font-size:14px;
						min-height:40px;
						&:hover{
							box-shadow: 1px 1px 15px rgba(255, 0, 0, 0.3);
						}
            &:after {
              display: none;
            }
          }
        }
        // Button Right
        &:nth-child(4) {
          left: 50%;
          border: 0;
        }
      }
    }
  }
}

// Tablet
@media only screen and (min-width: 768px) {
  .user_mode {
    .hero-container {
      .column {
        // Title - 2 Lines
        .pageEl:nth-child(2) {
          top: 33%;
          left: 7%;
          .text {
            h1 {
              &:first-of-type {
                font-size: 28px;
              }
              &:last-of-type {
                font-size: 50px;

                &:after {
                  content: '';
                  position: absolute;
                  bottom: -10px;
                  left: 0;
                  width: 42%;
                  border-bottom: 2px solid $secondary-color;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Desktop - Narrow
@media only screen and (min-width: 1024px) {
  .user_mode {
    .hero-container {
      padding: 0;
      // Scroll circle
      &:after {
        content: '';
        font-size: 12px;
        color: #fff;
        text-align: center;
        line-height: 160px;
        position: absolute;
        bottom: -127px;
        width: 255px;
        background-color: rgba(255, 0, 0, 0.5);
        height: 255px;
        display: block;
        border-radius: 50%;
				left: calc(50% - 127px);
      }
      .column {
        // Title - 2 Lines
        .pageEl:nth-child(2) {
          position: absolute;
          top: 16%;
          left: 0;
          width: 100%;
          max-width: $content-area-width;
          @if $main-nav-width-max != 100% {
            padding: 0 0 0 calc(50% - #{$main-nav-width-max / 2});
          }
          .text {
            padding-left: 30px;
            h1 {
              &:first-of-type {
                font-size: 60px;
              }
              &:last-of-type {
                font-size: 75px;

                &:after {
                  content: '';

                  width: 26%;
                }
              }
            }
            p {
              display: none;
              // padding-top: 30px;
              // display: block;
              // color: #fff;
              line-height: 1.5;
              // max-width: 400px;
            }
          }
        }
        // Red Buttons
        .pageEl {
          // Button left
          &:nth-child(n + 3) {
            position: absolute;
            bottom: unset;
            top: 70%;
            left: 30px;
            width: 150px;
            border: 0;
            // @if $main-nav-width-max != 100% {
            //   left: calc(50% - #{$main-nav-width-max / 2});
            // }
            a {
              justify-content: center;
              text-transform: none;
              border-radius: 8px;
              line-height: 30px;
              &:after {
                display: none;
              }
            }
          }
          // Button Right
          &:nth-child(4) {
            left: 200px;
            // @if $main-nav-width-max != 100% {
            //   left: calc(50% - #{$main-nav-width-max / 2} + 180px);
            // }
          }
        }
      }
      .column-1 {
				position: relative;
				&:before {
					content: 'SCROLL';
					font-size: 14px;
					color: #fff;
					text-align: center;
					position: absolute;
					width: 100px;
					height: 25px;
					display: block;
					left: calc(50% - 50px);
					bottom:50px;
					z-index:100;

				}
        &:after {
          content: '\f107';
          font-family: 'FontAwesome';
          font-size: 16px;
          text-align: center;
          font-weight: bold;
          color: #fff;
          position: absolute;
          display: block;
          width: 30px;
          left: calc(50% - 15px);
          bottom: 15px;
					z-index: 20;
					-moz-animation: bounce 2s infinite;
					-webkit-animation: bounce 2s infinite;
					animation: bounce 2s infinite;
        }
      }
    }
  }
}

// Desktop - Wide
@media only screen and (min-width: 1360px) {
  .user_mode {
    .hero-container {
      padding: 0;
      // Scroll circle

      .column {
        // Title - 2 Lines
        .pageEl:nth-child(2) {
          position: absolute;
          top: 15%;
          left: 0;
          width: 100%;
          max-width: $content-area-width;
          // @if $main-nav-width-max != 100% {
          //   padding: 0 calc(50% - #{$main-nav-width-max / 2});
          // }
          .text {
            padding-left: 15px;
            h1 {
              &:first-of-type {
                font-size: 70px;
              }
              &:last-of-type {
                font-size: 80px;

                &:after {
                  content: '';

                  width: 26%;
                }
              }
            }
            p {
              padding-top: 30px;
              display: block;
              color: #fff;
              line-height: 1.6;
              max-width: 500px;
            }
          }
        }
        // Red Buttons
        .pageEl {
          // Button left
          &:nth-child(n + 3) {
            position: absolute;
            bottom: unset;
            top: 75%;
            //left: 30px;
            width: 150px;
            border: 0;
            @if $main-nav-width-max != 100% {
              left: calc(51% - #{$main-nav-width-max / 2});
            }
            a {
              justify-content: center;
              text-transform: none;
              border-radius: 8px;
              line-height: 30px;
              &:after {
                display: none;
              }
            }
          }
          // Button Right
          &:nth-child(4) {
            //left: 200px;
            @if $main-nav-width-max != 100% {
              left: calc(51% - #{$main-nav-width-max / 2} + 180px);
            }
          }
        }
      }

    }
  }
}

// Desktop - x-Wide
@media only screen and (min-width: 1600px) {
  .user_mode {
    .hero-container {
      padding: 0;
      // Scroll circle

      .column {
        // Title - 2 Lines
        .pageEl:nth-child(2) {
          .text {
            p {
              line-height: 2;
            }
          }
        }
      }
    }
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

