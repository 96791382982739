.user_mode {
  .mission-container-top {
    background-color: $gray-lt-2;
    padding-top: 35px;
    .text {
      text-align: center;
    }
    h3 {
      font-family: $font-secondary;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: $primary-color;
      position: relative;
      display: inline-block;
      &:before,
      &:after {
        content: '';
        border-bottom: 2px solid #d8d8d8;
        position: absolute;
        top: 50%;
        left: -30px;
        width: 22px;
      }
      &:after {
        content: '';
        left: unset;
        right: -30px;
      }
    }
    h2 {
      font-family: $font-secondary;
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      color: $gray-dk-2;
      padding-bottom: 20px;
    }

    p {
      font-size: 14px;
      line-height: 2;
      color: $gray-md-2;
      max-width: 765px;
      margin: auto;
    }
  }
  .mission-container-bot {
		background-color: $gray-lt-2;
		padding-bottom:40px;

    .column {
      .pageEl {
        padding: 0 5px;

        .textBlockElement {
          background-color: white;
          border-radius: 10px;
					padding: 20px;
					box-shadow:0 0 10px transparent;
					transition: box-shadow .2s ease;
          .leftTextImage,
          .rightTextImage {
            float: none;
            margin: 0;
            position: relative;
            padding-bottom: 15px;
            img {
              width: 50px;
            }
            &:after {
              content: '';
              position: absolute;
              display: block;
              border-bottom: 3px solid $gray-lt;
              width: 40px;
              left: calc(50% - 20px);
              bottom: 10px;
            }
          }
          h5 {
            font-size: 18px;
            font-weight: 700;
            color: $gray-dk-1;
            text-transform: none;
          }
          a {
            font-size: 12px;
            padding-top: 10px;
            color: $gray-md-2;
            text-align: left;
            display: block;
            border-top: 1px solid $gray-md-1;
            position: relative;
            &:after {
              content: '>';
              font-size: 16px;
              color: $secondary-color;
              text-align: right;
              position: absolute;
              display: block;
              width: 25px;
              height: 25px;
              top: 5px;
              right: 0;
              transition: right 0.2s ease;
            }
            &:hover:after {
              right: 10px;
						}
						&:hover {
							color:$secondary-color;
						}
					}
					&:hover {

							box-shadow:0 0 10px $gray-md-1;

					}
        }
      }
    }
  }
}

// Desktop
@media only screen and (min-width: 1024px) {
  .user_mode {
    .mission-container-top {
      background-color: $gray-lt-2;
      padding-top: 80px;
      h3 {
        font-size: 14px;
      }
      h2 {
        font-size: 36px;
        padding-bottom: 20px;
      }
      p {
        font-size: 16px;
      }
    }
    .mission-container-bot {
			padding-bottom:90px;
      .column {
        display: flex;
        justify-content: space-around;

        .pageEl {
          flex:1 1 0;
          .textBlockElement img {
          }
        }
      }
    }
  }
}
