.user_mode {
	.social-container {
		padding: 42px 0 15px 0;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				135deg,
				rgba(1, 33, 99, 1) 0%,
				rgba(212, 23, 38, 1) 100%
			);
			opacity: 0.8;
		}
		.codeElement {
			text-align: center;
		}

	}
}
// Desktop
@media only screen and (min-width: 1024px) {
	.user_mode {
		.social-container {
			padding: 65px 0 65px 0;
		}
	}
}
