.user_mode {
	.global-footer {
		background-color: $gray-md-1;
		padding-top: 30px;
		padding-bottom: 30px;
		a {
			color: $gray-md-2;
		}
		.column-1 {
			padding-bottom: 20px;
			.pageEl {
				max-width: 70%;
				margin: auto;
				&:after {
					content: "";
					position: relative;
					bottom: -15px;
					width: 80%;
					margin: auto;
					display: block;
					border-bottom: 1px solid $gray-lt-3;
				}
			}
		}
		.column-2 {
			display: none;
		}

		.column-3 {
			.textBlockElement {
				h3 {
					text-align: center !important;
					text-transform: none;
					font-size: 18px;
					font-weight: 400;
				}
				.text {
					text-align: center;
					p {
						margin-bottom: 0;
					}
				}
			}
			.sn-social-media-list {
				text-align: center;

				&.dark .sn-social-media-icon {
					background: $secondary-color;
					border-radius: 10px;
					height: 2em;
					line-height: 2em;
					width: 2em;
					&:before {
						color: white;
						font-size: 1em;
						line-height: 2em;
						width: 2em;
					}
					&:hover {
						background-color: $hover-color;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1024px) {
	.user_mode {
		.global-footer {
			.column-1 {
				display: flex;
				justify-content: center;
				align-items: center;
				.pageEl {
					max-width: 100%;
					&:after {
						display: none;
					}
				}
			}
			.column-2 {
				display: flex;
				flex-flow: row wrap;
				align-content: flex-start;
				padding-top: 30px;
				.pageEl {
					flex-basis: 50%;
					position: relative;
					&:first-child {
						flex-basis: 100%;
					}
				}
				&:before,
				&:after {
					content: "";
					border-left: 1px solid $gray-lt-3;
					width: 1px;
					position: absolute;
					top: 25%;
					left: -20px;
					height: 50%;
				}
				&:after {
					left: unset;
					right: 20px;
				}
				.textBlockElement {
					margin-bottom: 0;
					margin-top: 0;
					a {
						font-weight: 400;
						&:hover {
							text-decoration: underline;
						}
					}
					h3 {
						text-align: left !important;
						text-transform: none;
						font-size: 18px;
						font-weight: 700;
					}
					.text {
						text-align: left;
						p {
							margin-bottom: 0;
							margin-top: 0;
						}
					}
				}
			}
			.column-3 {
				padding-top: 15px;
				.textBlockElement {
					h3 {
						text-align: left !important;
						font-weight: 700;
					}
					.text {
						text-align: left;
					}
				}
				.sn-social-media-list {
					text-align: left;
				}
			}
		}
	}
}

.has-mobile-nav {
	.user_mode {
		.contact-us {
			h3 span {
				color: $gray-dk-2;
				font-weight: 700;
			}
		}
	}
}
