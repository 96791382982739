.custom-report {
	font-family: $font-primary;
	.reportTableElementHeader > h3 {
		display: none;
	}
	.report-container {
		border: 0;
	}
	.reportTable {
		margin: 0;
		thead tr th:nth-child(2) {
			display: none;
		}
		tbody tr td {
			line-height: 1.3;
			&:nth-child(2) {
				display: none;
			}
			.club-code {
				font-size: 16px;
				font-weight: 400;
			}
		}

		th,
		td {
			font-size: 14px;
			text-align: center;
		}
		td {
			white-space: nowrap;
			position: relative;
			text-align: center;
		}
		tr th {
			font-weight: 700;
			&:first-child {
				text-align: left;
			}
		}
		tr td {
			&:first-child {
				font-size: 20px;
				font-weight: 900;
				color: $gray-dk-2;
				text-align: left;
			}
			&:after {
				content: "";
				border-right: 1px solid $gray-md-1;
				height: 25px;
				width: 1px;
				display: block;
				position: absolute;
				right: 0;
				top: calc(50% - 12px);
			}
			&:last-child {
				a {
					background: $secondary-color;
					color: white;
					border-radius: 5px;
					padding: 10px 30px;
					min-width: 150px;
					max-width: 150px;
					overflow: hidden;
					display: block;
					margin: auto;
					&:hover {
						background-color: $hover-color;
					}
				}

				&:after {
					display: none;
				}
			}
		}
		th {
			background-color: $primary-color;
			color: white;
			text-transform: uppercase;
			&.ajax_sort {
				//pointer-events: none;
				padding: 17px 25px 16px 16px;
				//&:after{ display: none; }
			}
			&.ajax_sort.ASC,
			&.ajax_sort.DESC,
			&.ajax_sort.highlighted,
			&.ajax_sort.sorttable_sorted,
			&.ajax_sort.sorttable_sorted_reverse {
				//background-color: transparent;
			}
			&:hover,
			&.ajax_sort:hover {
				color: $gray-md-1;
			}
		}
		tbody td {
			background-color: transparent;
			border-color: $gray-md-1;
			border-style: solid;
			border-width: 0 0 1px 0;
			height: 100px;
		}
		[sorttable_customkey="Yes"] {
			&:before {
				content: "";
				width: 20px;
				height: 20px;
				background-image: url(../images/VC_Accredited-Club.jpg);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
	.user_filters {
		margin-bottom: 25px !important;
		#user_filters_form {
			margin: 0 -5px;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
		}
		select,
		button {
			margin: 5px 5px;
		}
		button {
			padding: 13px 40px;
			border-radius: 5px;
			font-size: 14px;
		}
		select {
			font-family: $font-primary;
			font-size: 16px;
			border: 1px solid $gray-md-1;
			border-radius: 0px;
			padding: 5px 50px 5px 15px;
			height: 45px;
			background: $gray-lt url(../images/custom/arrow.svg);
			background-size: 12px;
			background-repeat: no-repeat;
			background-position: center right 15px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
	}
}

.custom-survey-link {
	&:before {
		content: "";
		font-family: fontawesome;
		font-size: 30px;
	}
}

.custom-visually-hidden {
	font-size: 0;
}

.title-block {
	.text h1 {
		@include special-header;
		text-transform: uppercase;
	}
}

.paginationElement {
	padding: 15px;
	background: white;
	border: solid 1px $gray-md-1;
	h4 {
		padding: 0;
		line-height: 1.3;
	}
	.results {
		font-size: 11px;
		padding: 0;
		margin: 0;
	}
	.paginationNav {
		padding: 0;
		margin-top: 7px;
		margin-bottom: -7px;
	}
}

.has-mobile-nav .pageElement.paginationElement {
	display: flex;
	flex-flow: column wrap;

	.results {
		order: 2;
	}

	.paginationNav {
		order: 3;
	}
}
