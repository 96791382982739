.custom-news-agg {
	.user_mode & {
		.newsAggregatorElement {
			border: 1px solid $gray-md-1;
			padding: 0 20px;
			h4 {
				a {
					color: $gray-dk-2;
					font-size: 18px;
				}
			}
			.teaser,
			.dateAuthor {
				display: none;
			}
			.readMore {
				a.button-small {
					background: none;
					color: $gray-md-2;
					padding: 0;
					text-transform: uppercase;
				}
			}
			.item {
				& > a img {
					max-width: 125px;
				}
			}
			.item:nth-of-type(1) {
				border-bottom: 1px solid #dee1e3;
			}
		}
	}
}

// .custom-news-agg {
// 	.user_mode & {
// 		.newsAggregatorElement {
// 			border: 1px solid $gray-md-1;
// 			padding: 0 20px;
// 			.dateAuthor,
// 			h4,
// 			.commentsAndMore {
// 				margin-left: 140px;
// 			}
// 			.item:nth-of-type(1) {
// 				padding: 20px 0 10px 0;
// 				border-bottom: 1px solid #dee1e3;
// 			}
// 		}
// 		.newsItemHeader {
// 			h2 {
// 				font-size: 18px;
// 				padding-left: 140px;
// 				a {
// 					color: black;
// 					font-weight: 800;

// 					&:hover {
// 						text-decoration: underline;
// 					}
// 				}
// 			}
// 			h4 {
// 				text-transform: none;
// 			}
// 			& > a {
// 				max-width: 125px;
// 				height: 63px;
// 				overflow: hidden;
// 				img {
// 					width: 125px;
// 					height: 63px;
// 				}
// 			}
// 			.newsText {
// 				display: none;
// 			}
// 		}
// 	}
// }
