// 1. From Theme
// 2. From Breakpoints
// 3. Default Styles

// 1. From Theme
.pageEl {
	.tabbedElement {
		ul.contentTabs {
			border: none;

			li {
				span {
					a {
						display: block;
					}
				}
			}
		}

		.tabContainer {
			.current {
				// There is an inline width set here.
				// Need to uddate tabElement to remove.
				width: 100% !important;
			}
		}
	}
}

// Edit Mode Fixes
.edit_mode {
	.contentTabs.rapidTabsConstruction {
		li span a {
			color: #666;
			background: transparent;
			border-right: none;
		}

		.selected span a {
			background: #fccd22;
			color: #333;
		}
	}
}

// 2. From Breakpoints
@media only screen and (max-width: 1024px) {
	.tabbedElement {
		ul.contentTabs {
			li {
				width: 33%;
				padding: 2px;

				span {
					a {
						width: 100%;
						display: block;
						text-align: center;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.tabbedElement {
		ul.contentTabs li {
			width: 50%;
			padding: 2px;

			span a {
				width: 100%;
				display: block;
				text-align: center;
			}
		}
	}
}

// 3. Default Styles
.contentTabs {
	display: inline-block;
	list-style-type: none;
	text-align: center;
	width: auto;
	margin: 10px auto;
	//border-bottom: 3px solid #ececec;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: " ";
		display: table;
	}

	a {
		color: $gray-md-2;
	}

	li {
		@include content-tab-item;

		span {
			background-repeat: no-repeat;
			background-position: bottom left;
			text-decoration: none;
			display: block;

			a {
				@include content-tab;

				span {
					display: inline;
				}
			}
		}

		&:hover,
		&.selected {
			background-color: $secondary-color;
			border-color: $secondary-color;
			a {
				color: white;
			}
		}
	}

	&.rapidTabsConstruction a.tabLink {
		padding-top: 4px;
		padding-left: 8px;
		display: inline-block;
		background: none;
	}
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs {
	margin: 0;
}

.dataTable .disabled {
	color: #000;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

/* Loading Tab Animation
-------------------------------------------------------------------- */
#contentTabsLoader {
	text-align: center;
	clear: both;
	padding: 25px;
}

/* Rapid Tabs Construction
-------------------------------------------------------------------- */
.rapidTabsConstruction {
	background: #fddd40;
	margin-bottom: 0;
	border-top: solid 1px #dda301;
	padding: 0 0 1px;

	li {
		border-right: solid 1px #dda301;
		padding-right: 0;

		a {
			font-size: 14px;
		}

		span {
			background: none;
			padding-left: 0;

			a {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

	.selected {
		background: #fde872;
	}
}

.tabbedElement {
	text-align: center;
	.tabContainer {
		text-align: left;
	}
}
@mixin tab-title-icons($title, $content) {
	&[data-title="#{$title}"] {
		&:before {
			content: "#{$content}";
			font-family: "usa-hockey-icons-2";
			text-transform: none;
			font-weight: normal;
		}
	}
}

@media screen and (max-width: 767px) {
	.mobile-accordion > {
		.contentTabs {
			display: none;
		}
		.tabContainer .layoutContainer {
			background: transparent none;
		}
		.tabContainer > div {
			display: none !important;
			position: static !important;
			width: 100% !important;
			height: auto !important;
		}
		.tabContainer > div.active {
			display: block !important;
		}
		.tabContainer {
			> li {
				list-style-type: none;
				margin-bottom: -1px;
				background-color: $gray-lt;
				transition: background-color $transition-linear;
				border:1px solid $gray-md-1;
				border-radius: 5px;
				position: relative;
				&:hover,
				&.active {
					background-color: $secondary-color;
					a{
						color:white;
					}
				}
				span {
					display: block;
				}
				a {
					display: flex;
					flex-flow: row;
					justify-content: center;
					align-items: center;
					text-decoration: none;
					text-align: center;
					color: $gray-dk-1;
					padding: 11px;
					width: 100%;
					font-size: 16px;
					font-weight: 400;
					line-height: 2;
					transition: color $transition-linear;
					position: relative;
					// &:before{
					//   padding-right: 10px;
					//   font-size: 20px;
					//   color: $primary-color;
					// }
					//@include tab-title-icons('membership', 1);
				}
				// &.active a:after{
				//   content: 'f';
				// }
				&:after {
					font-size: 14px;
					color: $gray-dk-1;
					content: "\f107";
					font-family: "FontAwesome";
					position: absolute;
					right: 15px;
					height: 25px;
					width: 25px;
					display: block;
					top: 30%;
					z-index: 1;
				}
				&.active:after {
					content: "\f106";
					color:white;
				}
				&:last-of-type{
					margin-bottom:0;
				}
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.mobile-accordion > {
		.tabContainer > li,
		.tabContainer > li.active {
			display: none;
		}
		.tabContainer > div {
			display: none !important;
			width: 100% !important;
			&.current {
				display: block !important;
				height: auto !important;
				position: relative !important;
				left: 0 !important;
			}
		}
	}
}
