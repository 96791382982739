.user_mode {
	.global-registration-btn-container {
		order: 1;
		padding-left: 10px;
		height: 80px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.nav-fixed & {
			height: 45px;
		}
		.global-registration-btn {
			.linkElement {
				padding: 0;
				margin: 0;
				h4 a {
					background: transparent;
					border: 1.5px solid $secondary-color;
					border-radius: 500px;
					color: $secondary-color;
					//font-size: 12px;
					padding: 15px 40px;
					text-transform: none;
					&:after {
						display: none;
					}
					&:hover {
						color: white;
						background-color: $secondary-color;
						box-shadow: none;
					}
					.nav-fixed & {
						padding: 3px 20px 5px 20px;
					}
				}
			}
		}
		&:after {
			display: none !important;
		}
	}
}
