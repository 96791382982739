// Page Title (display Body Header)
$page-title-full-width: false !default;
$page-title-full-width-text: false !default;
$page-title-container-background: $content-area-background !default;
$page-title-text-color: #333 !default;
$page-title-font-size: 30px !default;
$page-title-text-case: none !default;
$page-title-font-weight: normal !default;
$page-title-text-align: null !default;

#displayBodyHeader {
	//background-color: $page-title-container-background;
	@if $page-title-full-width != true {
		@include content-area-width;
	}
	@include page-title-container;
	display: flex;
	height: 265px;
	align-items: center;
	justify-content: center;
	position: relative;
	span {
		display: flex;
		//height: 100%;
		flex-basis: 100%;
		max-width: 100%;
		justify-content: center;
		align-items: center;
		color: $page-title-text-color;
		font-size: $page-title-font-size;
		// font-size: 4.5vw;
		//white-space: nowrap;
		text-transform: $page-title-text-case;
		font-weight: $page-title-font-weight;
		text-align: $page-title-text-align;
		line-height: 1.1;
		position: relative;
		z-index: 100;
		//overflow:hidden;

		@if $page-title-full-width-text != true and $page-title-full-width == true {
			//@include content-area-width;
		}
		//@include page-element-padding;
		@include content-container-padding(2);
		@include page-title-text;

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: calc(50% - 100px);
			width: 200px;
			height: 50px;
			bottom: -40px;
			background: url(../images/custom/cta-logo-1.svg) center center no-repeat;
		}
	}
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		top: 0;
		border-radius: 6px;
		background-color: rgba($primary-color, 0.4);
	}
	&.displayBodyHeader-image {
		height: auto !important;
		padding-top: 0px !important;
		@include page-title-image-container;
	}
}

.has-mobile-nav {
	#displayBodyHeader {
		width: calc(100% - 30px);
		height: 70px;
		span {
			font-size: 20px;
			line-height: 1;
			margin-top: -5px;
			&:after {
				left: calc(50% - 50px);
				width: 100px;
				height: 20px;
				bottom: -17px;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	#displayBodyHeader {
		span {
			font-size: 30px;
		}
	}
}

@media only screen and (min-width: 1025px) {
	#displayBodyHeader {
		span {
			font-size: 60px;
		}
	}
}

@media only screen and (min-width: 1280px) {
	#displayBodyHeader {
		span {
			font-size: 75px;
		}
	}
}

@media only screen and (min-width: 1440px) {
	#displayBodyHeader {
		span {
			font-size: 80px;
		}
	}
}
