// From _site_container.scss
.yieldPageContent {
	padding: 0;
}

body:not(.home) {
	.yieldPageContent {
		max-width: $content-area-width;
		margin-left: auto;
		margin-right: auto;
		box-shadow: 0 0 0 1px $gray-md-1;
		border-radius: 6px;
		overflow: hidden;
		background:white;
	}
}
.has-mobile-nav {
	body:not(.home) .yieldPageContent {
		margin: 20px 15px 30px 15px;
		padding-top: 0;
	}
}

.user_mode {
	#siteFooter {
		a.legalLink[href="/help"] {
			display: none;
		}
	}
}
