.accordion {
	.user_mode & {
		.pageElement {
			margin-top: 0;
			margin-bottom: 0;
		}
		h3 {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			background-color: $secondary-color;
			border-radius: 4px;
			height: 90px;
			color: white;
			font-size: 18px;
			font-weight: 700;
			text-transform: none;
			padding-left: 80px;
			position: relative;
			margin-bottom: 0;
			cursor: pointer;
			transition: padding 0.2s ease;
			&:after {
				content: "+";
				font-size: 18px;
				font-weight: 700;
				color: white;
				position: absolute;
				display: block;
				width: 20px;
				height: 20px;
				right: 25px;
				top: 30%;
			}
			&:before {
				content: " ";
				position: absolute;
				display: block;
				width: 45px;
				height: 45px;
				left: 20px;
				top: 28%;
				background-size: contain;
			}
			&:hover {
				padding-left: 90px;
			}
		}

		.text {
			height: 0;
			overflow: hidden;
			color: $gray-md-2;
			font-weight: 400;
			border: 1px solid $gray-md-1;
			border-top: 0;
			border-radius: 0 0 4px 4px;
			padding: 0 20px;
			transition: all 0.2s ease-in-out;

			a {
				font-weight: 400;
				color: $gray-md-2;
				&:before {
					content: "\f105";
					font-family: "FontAwesome";
					font-size: 16px;
					font-weight: 400;
					color: $secondary-color;
					position: relative;
					padding-right: 15px;
				}
				&:hover {
					color: $hover-color;
				}
			}
		}
	}

	&.active {
		h3 {
			border-radius: 4px 4px 0 0;
			&:after {
				content: "-";
			}
		}
		.text {
			height: auto;
			padding: 20px 20px 25px 20px;
			margin-bottom: 15px;
		}
	}

	&.icon-register h3:before {
		background: url(../images/custom/ico_registration_white.svg);
	}
	&.icon-events h3:before {
		background: url(../images/custom/ico_event_white.svg);
	}
	&.icon-officials h3:before {
		background: url(../images/custom/ico_officials_white.svg);
	}
	&.icon-coaches h3:before {
		background: url(../images/custom/ico_coach_white.svg);
	}
	&.icon-contact h3:before {
		background: url(../images/custom/ico_contact_white.svg);
	}
	&.icon-certifications h3:before {
		background: url(../images/custom/ico_certifications_white.svg);
	}
	&.icon-extra h3:before {
		background: url(../images/custom/ico_extra_white.svg);
	}
	&.icon-form h3:before {
		background: url(../images/custom/ico_form_white.svg);
	}
	&.icon-help h3:before {
		background: url(../images/custom/ico_help_white.svg);
	}
	&.icon-information h3:before {
		background: url(../images/custom/ico_information_white.svg);
	}
	&.icon-logo h3:before {
		background: url(../images/custom/ico_logo_icon_white.svg);
	}
	&.icon-persona h3:before {
		background: url(../images/custom/ico_persona_white.svg);
	}
	&.icon-policies h3:before {
		background: url(../images/custom/ico_policies_white.svg);
	}
	&.icon-trophy h3:before {
		background: url(../images/custom/ico_trophy_white.svg);
	}
}
