.has-mobile-nav {
	.user_mode {
		.custom-cta {
			&.pageEl {
				background-repeat: no-repeat;
				background-size: cover;
				overflow: hidden;
				position: relative;
				margin-bottom: 1px;
				height: 80px;
				transition: height 0.2s linear;
				&:after {
					content: "";
					background: $primary-color url(../images/custom/bg-mobile-cta.jpg)
						left top repeat;
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
				}
				&.cta-expand {
					height: 335px;
					.textBlockElement .text a {
						font-size: 18px;
						line-height: 35px;
					}
				}
			}
			.textBlockElement [class$="TextImage"] {
				width: initial !important;
				img {
					display: none;
				}
			}
			.textBlockElement {
				margin-top: 0;
				margin-bottom: 0;
				min-height: 80px;
				position: relative;
				z-index: 1;

				h3 {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 10px;
					margin: 0;
					height: 80px;
					transition: all 0.3s ease-in-out;
					span {
						font-family: $fontOne;
						font-size: 26px;
						font-weight: 900;
						line-height: 26px;
						text-transform: uppercase;
						color: #fff;
						font-style: normal;
					}
				}
				.text {
					padding: 0 15px 20px;
					a {
						font-family: $font-primary;
						font-size: 14px;
						color: #fff;
					}
				}
				&:hover {
					h3 {
						margin-top: 0;
						padding-bottom: 10px;
					}
				}
			}
		}
	}
}

.has-main-nav {
	.user_mode {
		.custom-cta.pageEl {
			background-repeat: no-repeat;
			background-size: cover;
			margin: 25px 0;
			min-height: 210px;
			max-height: 210px;
			overflow: hidden;
			position: relative;

			&:after {
				content: "";
				background-color: $primary-color;
				opacity: 0.5;
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
			&.cta-no-image {
				.textBlockElement h3 {
					margin-top: 0;
					background-color: $primary-color;
					padding-bottom: 10px;
				}
			}
			&:hover {
				.textBlockElement {
					h3 {
						padding-bottom: 0;
						&:before {
							top: -35px;
							height: 25px;
							transform: rotate(360deg);
						}
						&:after {
							opacity: 0;
							height: 1px;
						}
					}
					.text p {
						padding: 0;
					}
				}
			}
			.textBlockElement [class$="TextImage"] {
				width: initial !important;
				img {
					display: none;
				}
			}
			.textBlockElement {
				margin-top: 0;
				margin-bottom: 0;
				//min-height: 240px;
				position: relative;
				z-index: 1;

				h3 {
					display: flex;
					align-items: center;
					justify-content: center;
					padding-top: 0;
					padding-bottom: 100px;
					margin-top: 15%;
					margin-bottom: 0;
					transition: all 0.3s ease-in-out;
					text-align: center !important;
					position: relative;
					&:before,
					&:after {
						content: "";
						display: block;
						border-right: 2px solid $secondary-color;
						position: absolute;
						top: -40px;
						left: 50%;
						height: 25px;
						width: 1px;
						transition: all 0.3s ease;
					}
					&:after {
						top: 40px;
					}
					span {
						font-family: $fontOne;
						font-size: 24px;
						line-height: 1;
						text-transform: uppercase;
						color: #fff;
						font-style: normal;
					}
				}
				.text {
					//background-color: $primary-color;
					padding: 0 15px 100px;
					text-align: center;
					p {
						margin: 0;
						padding: 15px 0;
						transition: font-weight 0.1s ease-in-out;
						transition: padding 0.5s ease-in-out;
					}
					a {
						font-family: $fontOne;
						font-size: 14px;
						color: #fff;
						font-weight: 400;

						&:hover {
							font-weight: 700;
						}
					}
				}
				&:hover {
					h3 {
						//margin-top: 0;
						//background-color: $primary-color;
						//padding-bottom: 10px;
					}
				}
			}
		}
	}
}

.edit_mode {
	.custom-cta {
		.leftTextImage,
		.rightTextImage {
			float: none;
		}
	}
}
