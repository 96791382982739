.has-mobile-nav {
  .user_mode {
    .theme-mobile-nav {
      .group-nav {
        list-style: none;
        li {
          a {
            color: white;
            font-size: 14px;
            display: block;
						background-color: red;
          }
        }
      }
    }
    .theme-search-bar .group-nav {
      display: none;
    }
  }
}

.has-main-nav {
  .user_mode {
    .theme-search-bar {
      padding-left: 10px;
    }
    .group-nav {
      list-style: none;
      display: flex;
      position: relative;
      padding-left: 30px;
      li {
        padding: 0 15px;
        border-right: 1px solid $gray-lt;
        a {
          color: $gray-dk-1;
          font-size: 12px;
          &:hover {
            color: $hover-color;
          }
        }
      }
      &:before {
        content: '\f105';
        position: absolute;
        top: 3px;
        left: 0;
        display: block;
        max-width: 25px;
        max-height: 20px;
        color: $gray-md-2;
        font-family: 'FontAwesome';
        font-size: 14px;
        line-height: 20px;
        background: url(../images/custom/persona.svg) left center no-repeat;
        padding-left: 30px;
      }
    }
  }
}
