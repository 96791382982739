.user_mode {
	.events-container {
		background: white url(../images/custom/bg-events.jpg) left top no-repeat;
		background-size: cover;
		.layoutContainer {
			background: transparent;
		}
		.pageElement.aggregatorElement.eventAggregatorElement {
			display: flex;
			flex-flow: column nowrap;

			.aggHeader {
				background: transparent;
				color: $gray-dk-2;
				box-shadow: none;
				padding-left: 40px;
				position: relative;
				&:before {
					content: "";
					width: 30px;
					height: 1px;
					display: block;
					position: absolute;
					left: 0;
					top: 50%;
					border-bottom: 2px solid $primary-color;
				}
			}

			// .aggHeader {
			// 	@include custom-element-header();
			// }

			a {
				color: $gray-dk-1;
				&:hover {
					color: $secondary-color;
				}
			}
			.tags a {
				color: $secondary-color;
				font-weight: 400;
			}
			.vevent ~ .vevent {
				border: 0;
			}
			.vevent {
				display: flex;
				flex-flow: column wrap;
				flex-basis: calc(100% / 3 - 15px);
				background-color: white;
				border: 1px solid $gray-md-1 !important;
				border-radius: 7px;
				margin: 15px 0;
				padding: 15px;
				box-shadow: 0 0 10px transparent;
				transition: box-shadow 0.2s ease;
				&:hover {
					box-shadow: 0 0 10px $gray-md-1;
				}
			}
			.dateImage {
				background: transparent;
				padding-left: 35px;
				text-align: left;
				width: auto;
				.month,
				.date {
					display: inline-block;
					color: $gray-md-2;
					font-size: 12px;
					font-weight: 700;
					padding: 0;
				}
				&:before {
					content: "\f073";
					font-family: "FontAwesome";
					font-size: 12px;
					line-height: 27px;
					color: white;
					text-align: center;
					background-color: $secondary-color;
					width: 26px;
					height: 26px;
					display: block;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 3px;
				}
			}
			.summary,
			.details {
				font-family: $fontOne;
				padding: 0;
				line-height: 1.3;
			}
			.summary {
				a {
					font-weight: 900;
				}
			}
			.location {
				text-transform: none;
				color: $gray-md-2;
			}

			.aggHeader,
			.extendedOptions {
				flex-basis: 100%;
			}
			.extendedOptions {
				justify-content: center;
				color: transparent;
				a {
					color:$gray-md-2;
					border: 1px solid $gray-md-1;
					border-radius: 6px;
					padding: 10px 20px;
					margin: 0 3px;
					&:hover {
						background-color: $gray-lt;
					}
				}
			}
		}
	}
}
.has-mobile-nav .user_mode {
	.events-container{
		padding-top:40px;
		padding-bottom:40px;
	}

}
// Desktop
@media only screen and (min-width: 1024px) {
	.user_mode {
		.events-container {
			padding-top:75px;
			padding-bottom:52px;
			.pageElement.aggregatorElement.eventAggregatorElement {
				display: flex;
				flex-flow: row wrap;
				.aggHeader {
					padding-left: 80px;
					&:before {
						width: 40px;
						left: 25px;
					}
				}
				.vevent {
					margin: 7px;
				}
			}
		}
	}
}
