.user_mode {
	.custom-sn-cta {
		margin-top: 10px;
		.pageElement {
			.sn-call-to-action {
				padding: 0;
				&:before,
				&:after {
					display: none;
				}
				img {
				}
				.sn-call-to-action-overlay {
					background: rgba($primary-color, 0.5);
					top: 0;
					height: 100%;
					.sn-call-to-action-overlay-inner {
						.sn-call-to-action-overlay-text {
							vertical-align: top;
							h4.sn-call-to-action-title {
								font-family: $fontOne;
								font-size: 24px;
								line-height: 1;
								color: #fff;
								text-transform: uppercase;
								font-weight: 900;
								margin: 65px 0 0 0;
								padding: 0 0 50px 0;
								position: relative;
								transition: padding 0.3s ease-in-out;
								&:before,
								&:after {
									content: "";
									display: block;
									border-right: 2px solid $secondary-color;
									position: absolute;
									top: -40px;
									left: 50%;
									height: 25px;
									width: 1px;
									transition: all 0.2s ease-in-out;
								}
								&:after {
									top: 40px;
								}
							}
							.sn-call-to-action-subtitle {
								max-height: 0;
								overflow: hidden;
								transition: max-height 0.2s ease-in-out,
									opacity 0.5s ease-in-out;
							}
						}
					}
				}
			}
			&:hover {
				h4.sn-call-to-action-title {
					padding: 0 0 0 0 !important;
					&:before {
						top: -35px;
						height: 25px;
						transform: rotate(360deg);
					}
					&:after {
						opacity: 0;
						height: 1px;
					}
				}
				.sn-call-to-action-subtitle {
					max-height: 30px !important;
				}
			}
		}
	}
}
