.has-mobile-nav {
	.user_mode {
		.cta-container {
			padding-left: 0;
			padding-right: 0;
			.column {
				display: flex;
				flex-flow: column;
				padding: 0;
			}
			.sn-call-to-action {
				max-height: 70px;
				min-height: 70px;
				transition: max-height 0.2s ease-in-out, min-height 0.2s ease-in-out;
				& > img {
					display: none;
				}

				.sn-call-to-action-overlay {
					background: $primary-color url(../images/custom/bg-mobile-cta.jpg)
						left top repeat;
					padding: 0;
					max-height: 70px;
					top: 0;
					left: 0;
					right: unset;
					bottom: unset;
					transition: max-height 0.2s ease-in-out, min-height 0.2s ease-in-out;
				}
				.sn-call-to-action-overlay-text {
					display: block;
				}
				.sn-call-to-action-title {
					font-family: $fontOne;
					font-size: 26px;
					font-weight: 900;
					line-height: 70px;
					text-transform: uppercase;
					color: #fff;
					font-style: normal;
					margin-bottom: 0;
					a{
						font-size:14;
						font-weight:400;
						line-height:1.5;
					}
				}
				&:before,
				&:after {
					display: none;
				}
			}
			.pageElement {
				margin: 0 0 1px 0;
			}
			.pageEl {
				&.cta-expand {
					.sn-call-to-action {
						max-height: 155px;
						min-height: 155px;

						.sn-call-to-action-overlay {
							max-height: 155px;
							min-height: 155px;
						}
					}
				}
			}
		}
	}
}

.has-main-nav {
	.user_mode {
		.cta-container {
			margin-left: 1px;
			margin-top: 1px;
			width: 100%;
			box-sizing: border-box;
			background: #fff;
			padding: 0;
			.column {
				display: flex;
				flex-flow: row wrap;
				padding: 0;
			}
			.pageEl {
				flex-basis: calc(25% - 1px);
				box-sizing: border-box;
				padding: 0;
				margin: 0 1px 1px 0;
			}
			.pageElement {
				margin: 0;
				padding: 0;
				.sn-call-to-action {
					padding: 0;
					&:before,
					&:after {
						display: none;
					}
					img {
					}
					.sn-call-to-action-overlay {
						background: rgba($primary-color, 0.5);
						top: 0;
						height: 100%;
						.sn-call-to-action-overlay-inner {
							.sn-call-to-action-overlay-text {
								vertical-align: top;
								h4.sn-call-to-action-title {
									font-family: $fontOne;
									font-size: 28px;
									line-height: 1;
									color: #fff;
									text-transform: uppercase;
									font-weight: 900;
									margin: 48% 0 0 0;
									padding: 0 0 50px 0;
									position: relative;
									transition: padding 0.3s ease-in-out;

									&:before,
									&:after {
										content: "";
										display: block;
										border-right: 2px solid $secondary-color;
										position: absolute;
										top: -65px;
										left: 50%;
										height: 50px;
										width: 1px;
										transition: all 0.2s ease-in-out;
									}
									&:after {
										top: 50px;
									}
								}
								.sn-call-to-action-subtitle {
									max-height: 0;
									overflow: hidden;
									transition: max-height 0.2s ease-in-out,
										opacity 0.5s ease-in-out;
										a{
											font-size:14;
											font-weight:400;
											line-height:1.5;
										}
								}
							}
						}
					}
				}
				&:hover {
					h4.sn-call-to-action-title {
						padding: 0 0 0 0 !important;
						&:before {
							top: -35px;
							height: 25px;
							transform: rotate(360deg);
						}
						&:after {
							opacity: 0;
							height: 1px;
						}
					}
					.sn-call-to-action-subtitle {
						max-height: 30px !important;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1260px) {
	// Desktop (1024px)
	.has-main-nav {
		.user_mode {
			.cta-container
				.pageElement
				.sn-call-to-action
				.sn-call-to-action-overlay
				.sn-call-to-action-overlay-inner
				.sn-call-to-action-overlay-text
				h4.sn-call-to-action-title {
				font-size: 36px;
			}
		}
	}
}
