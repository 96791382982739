.user_mode {
	.news-container {
		background-color: $gray-lt;
		padding-top: 22px;
		padding-bottom: 40px;

		// Featured Stories
		.featured-stories {
			.aggHeader {
				font-size: 20px;
			}
			.item {
				background: white;
				border: 0;
				border-radius: 0 0 5px 5px;
				box-shadow: inset 0 0 0 1px $gray-md-1;
				margin-bottom: 20px;
				padding: 0 0 15px 0;
				&:first-of-type {
					margin-top: 15px;
				}
				& > a {
					position: relative;
					display: block;
					float: none;
					width: 100%;
					padding: 0 0 30px 0;
					height: 0;
					padding-bottom: 50% !important;
					overflow: hidden;

					img {
						width: 100%;
					}
				}

				h4 {
					padding-top: 10px;
					line-height: 1.2;
					a {
						color: $gray-dk-2;
						font-size: 20px;
						font-weight: 800;
					}
				}
				& > ul {
					padding: 35px 10px 0 15px;
					.teaser {
						text-transform: none;
						font-size: 16px;
					}
					.readMore {
						display: block;

						a {
							display: block;
							background: transparent;
							font-size:14px;
							color: $gray-md-2;
							text-align: center;
							text-transform: uppercase;
							position: relative;
							&:before,
							&:after {
								content: "";
								display: block;
								position: absolute;
								left: 0;
								top: 50%;
								width: 30%;
								border-bottom: 1px solid $gray-md-1;
							}
							&:after {
								left: unset;
								right: 0;
							}
							&:hover {
								color: $secondary-color;
								box-shadow:none;
							}
						}
					}
				}
				.dateAuthor {
					display: flex;
					justify-content: space-between;
					.persona {
						background-color: $secondary-color;
						font-size: 12px;
						font-weight: bold;
						color: white;
						text-transform: uppercase;
						padding: 7px 20px;
						border-radius: 6px;
						margin-top: -5px;
					}
					.newsAuthor {
						color:#fff;
						visibility: hidden;
						max-width: 1px;
						max-height: 1px;
					}
					.newsDate {
						&:before {
							content: "\f017";
							font-family: "FontAwesome";
							font-size: 13px;
							position: relative;
							left: -5px;
						}
					}
				}
			}
			.expanded .newsItemElement h4 {
				text-align: left;
				padding: 0 15px;
			}
			.extendedOptions {
				display: none;
			}
		}
		// Recent News
		.recent-news {
			.aggHeader {
				font-size: 20px;
			}
			.item {
				background-color: white;
				padding: 10px 15px 0 15px;
				border: 1px solid $gray-md-1;
				border-top: 0;
				border-bottom: 0;

				&:first-of-type {
					border-radius: 7px 7px 0 0;
					border-top: 1px solid $gray-md-1 !important;
					padding-top: 20px;
				}
				&:last-of-type {
					border-radius: 0 0 7px 7px;
					border-bottom: 1px solid $gray-md-1;
					margin-top: 0;
					padding: 15px;
				}
			}
			.newsItemElement {
				.newsItemHeader {
					padding-left: 0;
					h2 a {
						color: $gray-dk-2;
						font-size: 14px;
						font-weight: 700;
						&:after {
							content: "";
							display: block;
							padding-top: 15px;
							border-bottom: 1px solid $gray-md-1;
						}
					}
					& > a,
					.dateAuthor {
						display: none;
					}
				}
				& > h4 {
					display: none;
				}
				.newsText {
					display: none;
				}
			}
		}
	}
}

@media only screen and (min-width: 1024px) {
	.user_mode {
		.news-container {
			padding-top:65px;
			padding-bottom:85px;
			.column-1 {
				padding-right: 15px;
			}
			.featured-stories {
				.newsAggregatorElement {
					display: flex;
					flex-flow: row wrap;
					.aggHeader {
						flex-basis: 100%;
					}
					.item {
						flex-basis: calc(50% - 15px);
						margin: 0;
						&.even {
							margin: 0 0 0 30px;
						}

						& > ul {
							padding: 35px 15px 0 20px;
						}
					}
					.newsItemHeader {
						padding-left: 0;

						h2 a:hover {
							text-decoration: underline;
						}
					}
					.aggHeader {
						font-size: 24px;
					}
				}
			}
			.recent-news {
				.aggHeader {
					font-size: 24px;
				}
				.item {
					padding: 10px 20px 0 20px;
				}
				.newsItemElement {
					.newsItemHeader h2 a {
						//padding: 0 15px;
						&:hover {
							color: $secondary-color;
							text-decoration: underline;
						}
					}
				}
				.extendedOptions a {
					font-weight: 400;
				}
			}
		}
	}
}
